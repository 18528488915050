import React from 'react';

import * as styles from '../../../styles/flex/calculators/bikerTitle.module.css';
import image from '../../../images/bikers_images/biker-title-image-min.jpg';

function BikerTitle() {
    return (
        <section className={styles.PageBanner}>
            <img src={image}/>
            <h2>Bikers</h2>

            <div className={styles.Triangle}>
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M598.97 114.72L0 0 0 120 1200 120 1200 0 598.97 114.72z" className={styles.ShapeFill}></path>
                </svg>
            </div>
        </section>
    )
}

export default BikerTitle;