import React from 'react';
import { Link } from 'gatsby';

import * as styles from '../../../styles/flex/home/calculatorCards.module.css';

function LockupCard() {
    return (
        <section className={styles.CalculatorCard}>
            <div className={styles.Container}>
                <div className={styles.LockupLeft}></div>
                <div className={styles.Right}>
                    <div className={styles.Content}>
                        <h2>Cocaine</h2>
                        <p>Earn money with this business by buying or stealing supplies for your staff to use and create sellable product.</p>
                        <Link to="/profit-calculators/gta-online-cocaine-lockup-profit-calculator" className={styles.LinkButton}>Lockup</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LockupCard;
