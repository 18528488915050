import React from 'react';
import Layout from '../../components/layout';
import Seo from '../../components/seo';

import BikerTitle from '../../components/flex/calculators/bikerTitle';
import AdsenseAd from '../../components/flex/other/AdsenseAd';
import ChooseBikerCalculator from '../../components/flex/calculators/chooseBikerCalculator';

function BikerBusinesses() {
    return (
        <Layout>
            <Seo title="All GTA Online Biker Businesses" description="All GTA Online Biker business profits, locations, upgrades, and costs." />
            <div>
                <BikerTitle/>
                <AdsenseAd/>
                <ChooseBikerCalculator/>
            </div>
        </Layout>
    )
}

export default BikerBusinesses;