import React from 'react';
import LockupCard from './lockupCard';
import ForgeryCard from './forgeryCard';
import CounterfeitCard from './counterfeitCard';
import FarmCard from './farmCard';
import LabCard from './labCard';
import Slide from 'react-reveal/Slide';

import * as styles from '../../../styles/flex/home/calculatorSelect.module.css';

function ChooseBikerCalculator() {

    const calculatorCards = [
        {
            title: 'Cocaine Lockup',
            card: <LockupCard/>
        },
        {
            title: 'Document Forgery',
            card: <ForgeryCard/>
        },
        {
            title: 'Counterfeit Cash',
            card: <CounterfeitCard/>
        },
        {
            title: 'Weed Farm',
            card: <FarmCard/>
        },
        {
            title: 'Meth Lab',
            card: <LabCard/>
        },
    ];

    return (
        <section className={styles.CalculatorSelect}>
            <h2>Choose Your GTAO Business</h2>
            <p>Make the most from your money in GTAO</p>
            <div>
                {calculatorCards.map(function(cards, index) {
                    return (
                        <Slide up key={index}>
                            <div>{cards.card}</div>
                        </Slide>
                    )
                })}
            </div>
        </section>
    )
}

export default ChooseBikerCalculator;
