// extracted by mini-css-extract-plugin
export var CalculatorCard = "calculatorCards-module--CalculatorCard--3Ruq0";
export var Container = "calculatorCards-module--Container--1ycCJ";
export var ArcadeLeft = "calculatorCards-module--ArcadeLeft--28I2m";
export var NightclubLeft = "calculatorCards-module--NightclubLeft--1udIT";
export var BunkerLeft = "calculatorCards-module--BunkerLeft--prh1e";
export var BikersLeft = "calculatorCards-module--BikersLeft--3SYOx";
export var LockupLeft = "calculatorCards-module--LockupLeft--haFIl";
export var ForgeryLeft = "calculatorCards-module--ForgeryLeft--1zVYr";
export var CounterfeitLeft = "calculatorCards-module--CounterfeitLeft--KD0W6";
export var FarmLeft = "calculatorCards-module--FarmLeft--3B_Ay";
export var LabLeft = "calculatorCards-module--LabLeft--2SLUg";
export var Right = "calculatorCards-module--Right--1al-P";
export var LinkButton = "calculatorCards-module--LinkButton--3cI6D";
export var animate = "calculatorCards-module--animate--2cvrU";